import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const BlackFriday = ({ location }) => {
  const title =
    "【2022年版・事例付き】ブラックフライデーTwitterプレゼント企画10選"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="【2022年版・事例付き】ブラックフライデーTwitterプレゼント企画10選"
          pagedesc="ハロウィンとクリスマスの間にあるイベント「ブラックフライデー」。実は大きな宣伝チャンスの時期なのです。本記事では、ブラックフライデー期間に開催されたTwitterプレゼント企画の事例を10選をご紹介します。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/black-friday.jpg"
          pagepath="/blog/black-friday"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は9分で読むことができます
            </div>
            <h1>
              【2022年版・事例付き】ブラックフライデーTwitterプレゼント企画10選
            </h1>
            <div className="blog__sns-container--top">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
             {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">公開日：2022年10月8日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="スマートフォンで買い物をしている男性"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/black-friday.jpg"
                className="w-full"
              />
            </figure>
            <p>
              ハロウィンが終われば次はクリスマスですね。11月はイベントがないと思われがちですが、実は「ブラックフライデー」があるんです。日本にもブラックフライデーは浸透しつつあり、たくさんの企業や店舗で大セールが行われます。
            </p>
            <p>
              クリスマスプレゼントを探し始める時期でもあり、大きな宣伝のチャンスとも言えるイベントです。
            </p>
            <p>
              そこで、今回はブラックフライデー期間に開催されたTwitterキャンペーンをご紹介します。
            </p>
            <p>
              ぜひともブラックフライデーに向けた企画として、Twitterを活用されてみてはいかがでしょうか。
            </p>

            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    ブラックフライデーって何？
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    ブラックフライデーTwitterプレゼント企画 事例10選
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id3" className="toc__link">
                        ・【ゲオ】のブラックフライデー　フォロー＆リツイートキャンペーン
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id4" className="toc__link">
                        ・【ミニストップ】ブラックフライデーキャンペーン
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id5" className="toc__link">
                        ・【カインズ】プレゼントキャンペーン
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id6" className="toc__link">
                        ・【ユナイテッドシネマ】フォロー＆リツイートキャンペーン
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id7" className="toc__link">
                        ・【ブラックサンダー】ブラックフライデー企画
                        フォロー＆リツイートキャンペーン
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id8" className="toc__link">
                        ・【QUOカードPay】ブラックフライデーキャンペーン
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id9" className="toc__link">
                        ・【ドン・キホーテ】MEGAブラックフライデーキャンペーン
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id10" className="toc__link">
                        ・【サンワダイレクト】ブラックフライデープレゼントキャンペーン
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id11" className="toc__link">
                        ・【モーリーファンタジー】ブラックフライデープレゼントキャンペーン
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id12" className="toc__link">
                        ・【バンダイナムコアミューズメント】ブラックフライデープレゼントキャンペーン
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id13" className="toc__link">
                    まとめ
                  </a>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id1">ブラックフライデーって何？</h2>
              <p>
                ブラックフライデーとはアメリカで11月の第4木曜日に行われる感謝祭の翌日の金曜日のことです。
              </p>
              <p>
                アメリカでは一年のうち最も買い物が行われる日だと言われています。
              </p>
              <p>
                また、クリスマスプレゼントを検討・購入し始める時期でもあり、お店側にとって重要な時期でもあるのです。
              </p>
              <p>
                参考：
                <a
                  href="https://englishlive.ef.com/ja-jp/blog/english-in-the-real-world/what-is-black-friday/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  ブラックフライデーとは 由来を“わかりやすく”説明！ | EF English
                  Liveの公式ブログ
                </a>
              </p>
            </div>
            <div>
              <h2 id="id2">ブラックフライデーTwitterプレゼント企画 事例10選</h2>
              <p>
                ブラックフライデー期間に開催されたTwitterキャンペーンご紹介します。
              </p>
            </div>
            <div>
              <h2 id="id3">
                【ゲオ】のブラックフライデー　フォロー＆リツイートキャンペーン
              </h2>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  ／<br />
                  ⚫ゲオのブラックフライデー⚫
                  <br />
                  　　 🎧11/19(金)から🎮
                  <br />＼<br />
                  <br />
                  ゲーム・家電・スマホのお得品・イベントが盛りだくさん▶️
                  <a href="https://t.co/LALPrOKUb2">https://t.co/LALPrOKUb2</a>
                  <br />
                  <br />
                  ✨２週連続！フォロー＆RTキャンペーン✨
                  <br />
                  第２弾は、抽選で3名様に『Nintendo Switch Joy-Con (L)
                  /（R)グレー』をプレゼント！{" "}
                  <a href="https://t.co/E9fXiZSvTm">
                    pic.twitter.com/E9fXiZSvTm
                  </a>
                </p>
                &mdash; ゲオ【GEO】 (@GEO_official){" "}
                <a href="https://twitter.com/GEO_official/status/1458970333948813312?ref_src=twsrc%5Etfw">
                  November 12, 2021
                </a>
              </blockquote>
              <h3>キャンペーン概要</h3>
              <p>
                <a
                  href="https://twitter.com/GEO_official"
                  target="_blank"
                  rel="noreferrer"
                >
                  ゲオTwitter公式アカウント
                </a>
                では、フォロー＆リツイートキャンペーンが行われました。公式Twitterアカウントをフォローし、対象ツイートをリツイートします。当選者には「Nintendo
                Switch Joy-Con」がプレゼントされるキャンペーンです。
              </p>
              <h3>キャンペーンタイプ</h3>
              <p>フォロー＆リツイート</p>
              <h3>参加方法</h3>
              <p>
                1.{" "}
                <a
                  href="https://twitter.com/GEO_official"
                  target="_blank"
                  rel="noreferrer"
                >
                  ゲオTwitter公式アカウント
                </a>
                をフォロー
              </p>
              <p>2. 対象ツイートをリツイート</p>
              <p>3. キャンペーン終了後、当選者にのみDM</p>
              <h3>結果通知</h3>
              <p>後日通知</p>
            </div>
            <div>
              <h2 id="id4">【ミニストップ】ブラックフライデーキャンペーン</h2>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  ＼フォロー＆RTで当たる／
                  <br />
                  <br />
                  ブラックフライデーといえば
                  <br />
                  ベルギーチョコソフトと贅沢クランチショコラは
                  <br />
                  試してくれたミミ？
                  <br />
                  ぜひ、食べてみてほしいミミ～
                  <br />
                  <br />
                  フォロー&amp;RTでベルギーチョコソフトまたは贅沢クランチショコラ無料券が抽選で合計１，０００名さまに当たる
                  <br />
                  詳細は⇒
                  <a href="https://t.co/xRlDSMSpPH">
                    https://t.co/xRlDSMSpPH
                  </a>{" "}
                  <a href="https://t.co/EirmwBQU1E">
                    pic.twitter.com/EirmwBQU1E
                  </a>
                </p>
                &mdash; ミニストップ公式アカウント (@ministop_fan){" "}
                <a href="https://twitter.com/ministop_fan/status/1197786867799711744?ref_src=twsrc%5Etfw">
                  November 22, 2019
                </a>
              </blockquote>
              <h3>キャンペーン概要</h3>
              <p>
                <a
                  href="https://twitter.com/ministop_fan"
                  target="_blank"
                  rel="noreferrer"
                >
                  ミニストップTwitter公式アカウント
                </a>
                では、インスタントウィン形式のフォロー＆リツイートキャンペーンが行われました。公式Twitterアカウントをフォローし、対象ツイートをリツイートします。当選者には「ベルギーチョコソフトまたは贅沢クランチショコラ」がプレゼントされるキャンペーンです。
              </p>
              <h3>キャンペーンタイプ</h3>
              <p>インスタントウィン（フォロー＆リツイート）</p>
              <h3>参加方法</h3>
              <p>
                1.{" "}
                <a
                  href="https://twitter.com/ministop_fan"
                  target="_blank"
                  rel="noreferrer"
                >
                  ミニストップTwitter公式アカウント
                </a>
                をフォロー
              </p>
              <p>2. 対象ツイートをリツイート</p>
              <p>3. 即時結果通知</p>
              <h3>結果通知</h3>
              <p>即時通知</p>
            </div>
            <div>
              <h2 id="id5">【カインズ】プレゼントキャンペーン</h2>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  ／<br />
                  となりのブラックカインズさん🌵
                  <a href="https://twitter.com/hashtag/%E3%83%96%E3%83%A9%E3%83%83%E3%82%AF%E3%83%95%E3%83%A9%E3%82%A4%E3%83%87%E3%83%BC?src=hash&amp;ref_src=twsrc%5Etfw">
                    #ブラックフライデー
                  </a>{" "}
                  <a href="https://twitter.com/hashtag/%E3%82%AD%E3%83%A3%E3%83%B3%E3%83%9A%E3%83%BC%E3%83%B3?src=hash&amp;ref_src=twsrc%5Etfw">
                    #キャンペーン
                  </a>
                  <br />＼<br />
                  <br />
                  ブラックフライデーを記念して「カインズオリジナルキッチングッズ」を抽選で3名様にプレゼント🎁
                  <br />
                  <br />
                  🌵参加方法🌵
                  <br />①
                  <a href="https://twitter.com/cainz_san?ref_src=twsrc%5Etfw">
                    @cainz_san
                  </a>
                  をフォロー
                  <br />
                  ②いいね&amp;リツイート
                  <br />
                  <br />
                  連絡方法：DM(3名様)
                  <br />
                  締切：12/3
                  <a href="https://twitter.com/hashtag/%E3%83%97%E3%83%AC%E3%82%BC%E3%83%B3%E3%83%88?src=hash&amp;ref_src=twsrc%5Etfw">
                    #プレゼント
                  </a>{" "}
                  <a href="https://twitter.com/hashtag/%E6%87%B8%E8%B3%9E?src=hash&amp;ref_src=twsrc%5Etfw">
                    #懸賞
                  </a>{" "}
                  <a href="https://t.co/h5XbumBpqm">
                    pic.twitter.com/h5XbumBpqm
                  </a>
                </p>
                &mdash; となりのカインズさん(カインズ公式) (@cainz_san){" "}
                <a href="https://twitter.com/cainz_san/status/1464024850998063106?ref_src=twsrc%5Etfw">
                  November 26, 2021
                </a>
              </blockquote>
              <h3>キャンペーン概要</h3>
              <p>
                <a
                  href="https://twitter.com/cainz_san"
                  target="_blank"
                  rel="noreferrer"
                >
                  カインズTwitter公式アカウント
                </a>
                では、フォロー＆リツイート＆いいねキャンペーンが行われました。公式Twitterアカウントをフォローし、対象ツイートをリツイート・いいねします。当選者には「カインズオリジナルキッチングッズ」がプレゼントされるキャンペーンです。
              </p>
              <h3>キャンペーンタイプ</h3>
              <p>フォロー＆リツイート＆いいね</p>
              <h3>参加方法</h3>
              <p>
                1.{" "}
                <a
                  href="https://twitter.com/cainz_san"
                  target="_blank"
                  rel="noreferrer"
                >
                  カインズTwitter公式アカウント
                </a>
                をフォロー
              </p>
              <p>2. 対象ツイートをリツイート・いいね</p>
              <p>3. キャンペーン終了後、当落者にのみDM</p>
              <h3>結果通知</h3>
              <p>後日通知</p>
            </div>
            <div>
              <h2 id="id6">
                【ユナイテッドシネマ】フォロー＆リツイートキャンペーン
              </h2>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  <a href="https://twitter.com/hashtag/QUO%E3%82%AB%E3%83%BC%E3%83%89Pay?src=hash&amp;ref_src=twsrc%5Etfw">
                    #QUOカードPay
                  </a>{" "}
                  10,000円分✨
                  <br />
                  が10名に{" "}
                  <a href="https://twitter.com/hashtag/%E3%81%9D%E3%81%AE%E5%A0%B4%E3%81%A7%E5%BD%93%E3%81%9F%E3%82%8B?src=hash&amp;ref_src=twsrc%5Etfw">
                    #その場で当たる
                  </a>
                  ‼️
                  <br />
                  <br />
                  【参加方法】
                  <br />①
                  <a href="https://twitter.com/UNITED_CINEMAS?ref_src=twsrc%5Etfw">
                    @UNITED_CINEMAS
                  </a>
                  をフォロー
                  <br />
                  ②この投稿をRT
                  <br />
                  ③▼こちらをクリックするとその場で抽選結果がわかる！
                  <a href="https://t.co/YY24UmMKcg">https://t.co/YY24UmMKcg</a>
                  <a href="https://twitter.com/hashtag/%E3%83%97%E3%83%AC%E3%82%BC%E3%83%B3%E3%83%88%E4%BC%81%E7%94%BB?src=hash&amp;ref_src=twsrc%5Etfw">
                    #プレゼント企画
                  </a>{" "}
                  <a href="https://twitter.com/hashtag/%E3%82%AD%E3%83%A3%E3%83%B3%E3%83%97%E3%83%AC?src=hash&amp;ref_src=twsrc%5Etfw">
                    #キャンプレ
                  </a>{" "}
                  <a href="https://twitter.com/hashtag/%E3%83%96%E3%83%A9%E3%83%83%E3%82%AF%E3%83%95%E3%83%A9%E3%82%A4%E3%83%87%E3%83%BC?src=hash&amp;ref_src=twsrc%5Etfw">
                    #ブラックフライデー
                  </a>{" "}
                  今日は{" "}
                  <a href="https://twitter.com/hashtag/%E3%81%84%E3%81%84%E5%A4%AB%E5%A9%A6%E3%81%AE%E6%97%A5?src=hash&amp;ref_src=twsrc%5Etfw">
                    #いい夫婦の日
                  </a>{" "}
                  <a href="https://twitter.com/hashtag/%E3%83%84%E3%82%A4%E3%83%B3%E3%83%86%E3%83%BC%E3%83%AB%E3%81%AE%E6%97%A5?src=hash&amp;ref_src=twsrc%5Etfw">
                    #ツインテールの日
                  </a>{" "}
                  <a href="https://t.co/kCwuUdlS8e">
                    pic.twitter.com/kCwuUdlS8e
                  </a>
                </p>
                &mdash; ユナイテッド・シネマ公式 (@UNITED_CINEMAS){" "}
                <a href="https://twitter.com/UNITED_CINEMAS/status/1197725821726670848?ref_src=twsrc%5Etfw">
                  November 22, 2019
                </a>
              </blockquote>
              <h3>キャンペーン概要</h3>
              <p>
                <a
                  href="https://twitter.com/UNITED_CINEMAS"
                  target="_blank"
                  rel="noreferrer"
                >
                  ユナイテッドシネマTwitter公式アカウント
                </a>
                では、インスタントウィン形式のフォロー＆リツイートキャンペーンが行われました。公式Twitterアカウントをフォローし、対象ツイートをリツイートします。当選者には「QUOカードPay
                10,000円分」がプレゼントされるキャンペーンです。
              </p>
              <h3>キャンペーンタイプ</h3>
              <p>インスタントウィン（フォロー＆リツイート）</p>
              <h3>参加方法</h3>
              <p>
                1.{" "}
                <a
                  href="https://twitter.com/UNITED_CINEMAS"
                  target="_blank"
                  rel="noreferrer"
                >
                  ユナイテッドシネマTwitter公式アカウント
                </a>
                をフォロー
              </p>
              <p>2. 対象ツイートをリツイート</p>
              <p>3. ツイート本文内のURLにて結果確認</p>
              <h3>結果通知</h3>
              <p>別サイト</p>
            </div>
            <div>
              <h2 id="id7">
                【ブラックサンダー】ブラックフライデー企画
                フォロー＆リツイートキャンペーン
              </h2>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  ／<br />
                  ブラックフライデー企画(&#39;-&#39;*)⚡️
                  <br />
                  フォロー＆RTキャンペーン
                  <br />＼<br />
                  ⚡️10名様に{" "}
                  <a href="https://twitter.com/hashtag/%E3%83%96%E3%83%A9%E3%83%83%E3%82%AF%E3%82%B5%E3%83%B3%E3%83%80%E3%83%BC?src=hash&amp;ref_src=twsrc%5Etfw">
                    #ブラックサンダー
                  </a>{" "}
                  100個プレゼント⚡️
                  <br />
                  <br />
                  1️⃣応募方法
                  <a href="https://twitter.com/Black_Thunder_?ref_src=twsrc%5Etfw">
                    @Black_Thunder_
                  </a>
                  をフォロー
                  <a href="https://twitter.com/hashtag/%E3%82%B6%E3%82%AF%E3%82%B6%E3%82%AF%E3%83%96%E3%83%A9%E3%83%83%E3%82%AF%E3%83%95%E3%83%A9%E3%82%A4%E3%83%87%E3%83%BC?src=hash&amp;ref_src=twsrc%5Etfw">
                    #ザクザクブラックフライデー
                  </a>{" "}
                  をつけてリツイート‼️
                  <br />
                  <br />
                  2️⃣締切
                  <br />
                  2020年11月27日まで
                  <br />
                  当選者に締め切り後DM致します。
                  <a href="https://twitter.com/hashtag/%E3%83%96%E3%83%A9%E3%83%83%E3%82%AF%E3%83%95%E3%83%A9%E3%82%A4%E3%83%87%E3%83%BC?src=hash&amp;ref_src=twsrc%5Etfw">
                    #ブラックフライデー
                  </a>{" "}
                  <a href="https://t.co/BTOIiOO1Vb">
                    pic.twitter.com/BTOIiOO1Vb
                  </a>
                </p>
                &mdash; ブラックサンダーさん【公式】 (@Black_Thunder_){" "}
                <a href="https://twitter.com/Black_Thunder_/status/1329622685555384321?ref_src=twsrc%5Etfw">
                  November 20, 2020
                </a>
              </blockquote>
              <h3>キャンペーン概要</h3>
              <p>
                <a
                  href="https://twitter.com/Black_Thunder_"
                  target="_blank"
                  rel="noreferrer"
                >
                  ブラックサンダーTwitter公式アカウント
                </a>
                では、フォロー＆リツイートキャンペーンが行われました。公式Twitterアカウントをフォローし、対象ツイートをリツイートします。当選者には「ブラックサンダー100個」がプレゼントされるキャンペーンです。
              </p>
              <h3>キャンペーンタイプ</h3>
              <p>フォロー＆リツイート</p>
              <h3>参加方法</h3>
              <p>
                1.{" "}
                <a
                  href="https://twitter.com/Black_Thunder_"
                  target="_blank"
                  rel="noreferrer"
                >
                  ブラックサンダーTwitter公式アカウント
                </a>
                をフォロー
              </p>
              <p>2. 対象ツイートをリツイート</p>
              <p>3. キャンペーン終了後、当選者にのみDM</p>
              <h3>結果通知</h3>
              <p>後日抽選</p>
            </div>
            <div>
              <h2 id="id8">【QUOカードPay】ブラックフライデーキャンペーン</h2>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  セールだとついつい衝動買い、、、
                  <br />
                  <br />
                  でもお買い物中の楽しい時間はプライスレスだよねー
                  <br />
                  <br />／<br />
                  今月は「ほぼ毎日」フォロー＆RTで10名様に1,000円分のQUOカードPayが当たるキャンペーン！
                  <br />＼
                  <a href="https://twitter.com/hashtag/11%E6%9C%8826%E6%97%A5%E5%AF%BE%E8%B1%A1%E3%83%84%E3%82%A4%E3%83%BC%E3%83%88?src=hash&amp;ref_src=twsrc%5Etfw">
                    #11月26日対象ツイート
                  </a>
                  <a href="https://twitter.com/hashtag/%E3%83%96%E3%83%A9%E3%83%83%E3%82%AF%E3%83%95%E3%83%A9%E3%82%A4%E3%83%87%E3%83%BC?src=hash&amp;ref_src=twsrc%5Etfw">
                    #ブラックフライデー
                  </a>{" "}
                  <a href="https://t.co/fYBJZ18ZZi">
                    pic.twitter.com/fYBJZ18ZZi
                  </a>
                </p>
                &mdash; QUOカードPay （クオとペイ） (@QUOcardPay){" "}
                <a href="https://twitter.com/QUOcardPay/status/1464081494838755328?ref_src=twsrc%5Etfw">
                  November 26, 2021
                </a>
              </blockquote>
              <h3>キャンペーン概要</h3>
              <p>
                <a
                  href="https://twitter.com/QUOcardPay"
                  target="_blank"
                  rel="noreferrer"
                >
                  QUOカードPayTwitter公式アカウント
                </a>
                では、フォロー＆リツイートキャンペーンが行われました。公式Twitterアカウントをフォローし、対象ツイートをリツイートします。当選者には「QUOカードPay1,000円分」がプレゼントされるキャンペーンです。
              </p>
              <h3>キャンペーンタイプ</h3>
              <p>フォロー＆リツイート</p>
              <h3>参加方法</h3>
              <p>
                1.{" "}
                <a
                  href="https://twitter.com/QUOcardPay"
                  target="_blank"
                  rel="noreferrer"
                >
                  QUOカードPayTwitter公式アカウント
                </a>
                をフォロー
              </p>
              <p>2. 対象ツイートをリツイート</p>
              <p>3. 結果通知</p>
              <h3>結果通知</h3>
              <p>不明</p>
            </div>
            <div>
              <h2 id="id9">
                【ドン・キホーテ】MEGAブラックフライデーキャンペーン
              </h2>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  🐧MEGAブラックフライデー{" "}
                  <a href="https://twitter.com/hashtag/%E3%82%AD%E3%83%A3%E3%83%B3%E3%83%9A%E3%83%BC%E3%83%B3?src=hash&amp;ref_src=twsrc%5Etfw">
                    #キャンペーン
                  </a>
                  🐧
                  <br />
                  <br />
                  10日連続！
                  <br />
                  フォロー&amp;RTで毎日10名様、合計100名様にmajicaギフト券1,000円分がその場で当たる！
                  <br />
                  11/20～11/29まで毎日がチャンス！
                  <br />
                  <br />
                  MEGAブラックフライデーキャンペーン 最終日スタート！
                  <br />
                  <br />
                  ■応募方法
                  <br />
                  ①ドンキ公式アカウントをフォロー
                  <br />
                  ②この投稿をRT{" "}
                  <a href="https://t.co/ZJlV9ypbF7">
                    pic.twitter.com/ZJlV9ypbF7
                  </a>
                </p>
                &mdash; 驚安の殿堂 ドン・キホーテ🐧 (@donki_donki){" "}
                <a href="https://twitter.com/donki_donki/status/1332700781565382664?ref_src=twsrc%5Etfw">
                  November 28, 2020
                </a>
              </blockquote>
              <h3>キャンペーン概要</h3>
              <p>
                <a
                  href="https://twitter.com/donki_donki"
                  target="_blank"
                  rel="noreferrer"
                >
                  ドン・キホーテTwitter公式アカウント
                </a>
                では、インスタントウィン形式のフォロー＆リツイートキャンペーンが行われました。公式Twitterアカウントをフォローし、対象ツイートをリツイートします。当選者には「majicaギフト券1,000円分」がプレゼントされるキャンペーンです。
              </p>
              <h3>キャンペーンタイプ</h3>
              <p>インスタントウィン（フォロー＆リツイート）</p>
              <h3>参加方法</h3>
              <p>
                1.{" "}
                <a
                  href="https://twitter.com/donki_donki"
                  target="_blank"
                  rel="noreferrer"
                >
                  ドン・キホーテTwitter公式アカウント
                </a>
                をフォロー
              </p>
              <p>2. 対象ツイートをリツイート</p>
              <p>3. 即時結果通知</p>
              <h3>結果通知</h3>
              <p>後日通知</p>
            </div>
            <div>
              <h2 id="id10">
                【サンワダイレクト】ブラックフライデープレゼントキャンペーン
              </h2>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  【フォロー＆RT{" "}
                  <a href="https://twitter.com/hashtag/%E3%83%97%E3%83%AC%E3%82%BC%E3%83%B3%E3%83%88?src=hash&amp;ref_src=twsrc%5Etfw">
                    #プレゼント
                  </a>
                  】<br />
                  音を身に着ける新感覚スピーカーが抽選で2名様に当たる！
                  <br />
                  <br />
                  ▼応募方法
                  <br />①{" "}
                  <a href="https://twitter.com/sanwadirect?ref_src=twsrc%5Etfw">
                    @sanwadirect
                  </a>{" "}
                  をフォロー
                  <br />② この投稿をRT
                  <br />
                  <br />
                  11/27迄
                  <br />
                  <br />＼ MAX99%OFF ＆ ポイント21倍 ／
                  <a href="https://twitter.com/hashtag/%E3%82%B5%E3%83%B3%E3%83%AF%E3%83%80%E3%82%A4%E3%83%AC%E3%82%AF%E3%83%88?src=hash&amp;ref_src=twsrc%5Etfw">
                    #サンワダイレクト
                  </a>{" "}
                  の{" "}
                  <a href="https://twitter.com/hashtag/%E3%83%96%E3%83%A9%E3%83%83%E3%82%AF%E3%83%95%E3%83%A9%E3%82%A4%E3%83%87%E3%83%BC?src=hash&amp;ref_src=twsrc%5Etfw">
                    #ブラックフライデー
                  </a>{" "}
                  開催中♪
                  <br />
                  詳細→
                  <a href="https://t.co/koHRqU5hSA">
                    https://t.co/koHRqU5hSA
                  </a>{" "}
                  <a href="https://t.co/3Oghq2BOvG">
                    pic.twitter.com/3Oghq2BOvG
                  </a>
                </p>
                &mdash; サンワダイレクト (@sanwadirect){" "}
                <a href="https://twitter.com/sanwadirect/status/1197682935177965568?ref_src=twsrc%5Etfw">
                  November 22, 2019
                </a>
              </blockquote>
              <h3>キャンペーン概要</h3>
              <p>
                <a
                  href="https://twitter.com/sanwadirect"
                  target="_blank"
                  rel="noreferrer"
                >
                  サンワダイレクトTwitter公式アカウント
                </a>
                では、フォロー＆リツイートキャンペーンが行われました。公式Twitterアカウントをフォローし、対象ツイートをリツイートします。当選者には「音を身につける新感覚スピーカー」がプレゼントされるキャンペーンです。
              </p>
              <h3>キャンペーンタイプ</h3>
              <p>フォロー＆リツイート</p>
              <h3>参加方法</h3>
              <p>
                1.{" "}
                <a
                  href="https://twitter.com/sanwadirect"
                  target="_blank"
                  rel="noreferrer"
                >
                  サンワダイレクトTwitter公式アカウント
                </a>
                をフォロー
              </p>
              <p>2. 対象ツイートをリツイート</p>
              <p>3. キャンペーン終了後、当選者にのみDM</p>
              <h3>結果通知</h3>
              <p>後日通知</p>
            </div>
            <div>
              <h2 id="id11">
                【モーリーファンタジー】ブラックフライデープレゼントキャンペーン
              </h2>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  ＼ほぐしコンビーフ4缶&amp;イオンギフトカード3000円分が当たる！／
                  <a href="https://twitter.com/hashtag/BLACKFRIDAY?src=hash&amp;ref_src=twsrc%5Etfw">
                    #BLACKFRIDAY
                  </a>{" "}
                  メダルガラポン開催記念！
                  <br />①
                  <a href="https://twitter.com/nozaki1948?ref_src=twsrc%5Etfw">
                    @nozaki1948
                  </a>
                  と
                  <a href="https://twitter.com/mollyfantasy_of?ref_src=twsrc%5Etfw">
                    @mollyfantasy_of
                  </a>
                  をフォロー！
                  <br />
                  ②このツイートをRT！
                  <br />
                  抽選で3名様にほぐしコンビーフ ハーブ&amp;ペッパー
                  4缶とイオンギフトカード3000円分をプレゼント♪
                  <a href="https://twitter.com/hashtag/%E3%83%96%E3%83%A9%E3%83%83%E3%82%AF%E3%83%95%E3%83%A9%E3%82%A4%E3%83%87%E3%83%BC?src=hash&amp;ref_src=twsrc%5Etfw">
                    #ブラックフライデー
                  </a>{" "}
                  <a href="https://t.co/1FqcO4Qq9J">
                    pic.twitter.com/1FqcO4Qq9J
                  </a>
                </p>
                &mdash; モーリーファンタジー【公式】 (@mollyfantasy_of){" "}
                <a href="https://twitter.com/mollyfantasy_of/status/1197447416569446400?ref_src=twsrc%5Etfw">
                  November 21, 2019
                </a>
              </blockquote>
              <h3>キャンペーン概要</h3>
              <p>
                <a
                  href="https://twitter.com/mollyfantasy_of"
                  target="_blank"
                  rel="noreferrer"
                >
                  モーリーファンタジーTwitter公式アカウント
                </a>
                では、フォロー＆リツイートキャンペーンが行われました。公式Twitterアカウントをフォローし、対象ツイートをリツイートします。当選者には「ほぐしコンビーフ4缶」＋「イオンギフトカード3,000円分」がプレゼントされるキャンペーンです。
              </p>
              <h3>キャンペーンタイプ</h3>
              <p>フォロー＆リツイート</p>
              <h3>参加方法</h3>
              <p>
                1.{" "}
                <a
                  href="https://twitter.com/mollyfantasy_of"
                  target="_blank"
                  rel="noreferrer"
                >
                  モーリーファンタジーTwitter公式アカウント
                </a>
                ・
                <a
                  href="https://twitter.com/nozaki1948"
                  target="_blank"
                  rel="noreferrer"
                >
                  ノザキTwitter公式アカウント
                </a>
                をフォロー
              </p>
              <p>2. 対象ツイートをリツイート</p>
              <p>3. 結果通知</p>
              <h3>結果通知</h3>
              <p>不明</p>
            </div>
            <div>
              <h2 id="id12">
                【バンダイナムコアミューズメント】ブラックフライデープレゼントキャンペーン
              </h2>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  ＼今年はあのふたりがブラックに！／
                  <br />
                  <br />
                  ブラックフライデーver.ぬいぐるみ
                  <br />
                  マイメロディ&amp;クロミ 登場記念！
                  <br />
                  10名様になかよくセットでプレゼント❤
                  <br />
                  <br />①
                  <a href="https://twitter.com/bnam_jp?ref_src=twsrc%5Etfw">
                    @bnam_jp
                  </a>
                  をフォロー
                  <br />
                  ②この投稿をRT
                  <br />
                  ✔️11/19(金)23:59まで
                  <br />
                  <br />
                  応募規約はこちら⇒
                  <a href="https://t.co/iyyTEMINZY">https://t.co/iyyTEMINZY</a>
                  <a href="https://twitter.com/hashtag/%E3%82%B5%E3%83%B3%E3%83%AA%E3%82%AA?src=hash&amp;ref_src=twsrc%5Etfw">
                    #サンリオ
                  </a>{" "}
                  <a href="https://twitter.com/hashtag/%E3%83%8A%E3%83%A0%E3%82%B3%E9%99%90%E5%AE%9A?src=hash&amp;ref_src=twsrc%5Etfw">
                    #ナムコ限定
                  </a>{" "}
                  <a href="https://t.co/qmH1GVe8pt">
                    pic.twitter.com/qmH1GVe8pt
                  </a>
                </p>
                &mdash; バンダイナムコアミューズメント公式アカウント (@bnam_jp){" "}
                <a href="https://twitter.com/bnam_jp/status/1455361582561566724?ref_src=twsrc%5Etfw">
                  November 2, 2021
                </a>
              </blockquote>
              <h3>キャンペーン概要</h3>
              <p>
                <a
                  href="https://twitter.com/bnam_jp"
                  target="_blank"
                  rel="noreferrer"
                >
                  バンダイナムコアミューズメントTwitter公式アカウント
                </a>
                では、フォロー＆リツイートキャンペーンが行われました。公式Twitterアカウントをフォローし、対象ツイートをリツイートします。当選者には「ほぐしコンビーフ4缶」＋「イオンギフトカード3,000円分」がプレゼントされるキャンペーンです。
              </p>
              <h3>キャンペーンタイプ</h3>
              <p>フォロー＆リツイート</p>
              <h3>参加方法</h3>
              <p>
                1.{" "}
                <a
                  href="https://twitter.com/bnam_jp"
                  target="_blank"
                  rel="noreferrer"
                >
                  バンダイナムコアミューズメントTwitter公式アカウント
                </a>
                をフォロー
              </p>
              <p>2. 対象ツイートをリツイート</p>
              <p>3. 結果通知</p>
              <h3>結果通知</h3>
              <p>不明</p>
            </div>
            <div>
              <h2 id="id13">まとめ</h2>
              <p>
                フォロー＆リツイートキャンペーンは簡単に応募できるので、参加者の集まりやすいキャンペーンです。インスタントウィン形式は参加者の期待や参加へのモチベーションを高めます。
              </p>
              <p>
                また、ブラックフライデーにちなんだインパクトのあるバナー画像を掲載することでユーザーの目に留まりやすく、更に参加意欲を上げることができます。
              </p>
              <p>PARKLoTでは今回ご紹介したキャンペーンに対応しております。</p>
              <div className="blog__border-highlight">
                <ul>
                  <li>
                    <a
                      href="/case-study/twitter/instant-win-follow-and-retweet"
                      target="_blank"
                      rel="noreferrer"
                    >
                      フォロー＆リツイート（インスタントウィン）
                    </a>
                  </li>
                  <li>
                    <a
                      href="/case-study/twitter/twitter-non-instant-win"
                      target="_blank"
                      rel="noreferrer"
                    >
                      フォロー＆リツイート（後日抽選）
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="kakefuda" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/halloween-twitter-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    ハロウィンのTwitterキャンペーン5選
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-sns-marketing-is-necessary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    なぜSNSマーケティングが必要なのか？Twitterキャンペーンの戦略について紹介
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/risks-of-campaign-implementation"
                    target="_blank"
                    rel="noreferrer"
                  >
                    プレゼントキャンペーンを社内で実施するリスクとその回避方法
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/twitter-hashtag-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのハッシュタグキャンペーンとは？効果的なハッシュタグの見つけ方は？基本的なSNSマーケティング戦略を公開
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/2021-campaign-result-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンを徹底比較！4つの比較テストから導き出した、成功の秘訣とは？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/examples-of-utilization"
                    target="_blank"
                    rel="noreferrer"
                  >
                    あのブランドはどうやってファンを獲得した?PARKLoT活用事例4選を解説!
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}
export default BlackFriday
